<template>
    <ContainerMain class="text-center">
        <MessagePreview
            :message-text="generatedMessageText"
            :is-streaming="!store.isStreamingIdle"
            :has-copy-button="true"
        />
        <TransitionExpands>
            <CenteredContainer
                v-show="store.isStreamingIdle"
                class="overflow-hidden"
            >
                <ButtonGenerateOther
                    class="mb-5"
                    label-t-key="user.campaign.comment_generate_other"
                    @generate-message="store.reformulateContentSample()"
                />
                <div v-if="isSocialMedia">
                    <AVButton
                        v-if="generatedMessageText && store.isStreamingIdle"
                        :sticky-on-mobile="true"
                        @click="
                            displayCopiedContentOverlay(generatedMessageText)
                        "
                        >{{ t('user.campaign.comment_copy') }}
                    </AVButton>
                </div>
                <div v-if="store.interpellationMode === 'form'">
                    <AVButton
                        v-if="generatedMessageText && store.isStreamingIdle"
                        :sticky-on-mobile="true"
                        @click="
                            displayCopiedContentOverlay(generatedMessageText)
                        "
                        >{{
                            store.interpellation!.custom_button_text
                                ? store.interpellation!.custom_button_text
                                : t('user.campaign.send_button_form')
                        }}
                    </AVButton>
                </div>

                <AVOverlayWhite
                    v-if="isShowingSendMsgOverlay"
                    @close="isShowingSendMsgOverlay = false"
                >
                    <div class="text-lg max-sm:text-left">
                        {{ t('user.campaign.comment_copied_to_clipboard') }}
                    </div>
                    <div v-if="isSocialMedia">
                        <MessageInstructions
                            :instructions-t-key="getInstructionTKey()"
                        />
                        <SendButtons
                            :send-links="selectedSendLinks"
                            @send-button-clicked="
                                () => {
                                    emit('sendButtonClicked')
                                    isShowingSendMsgOverlay = false
                                }
                            "
                            :campaign-id="props.campaignId"
                        />
                        <AVButton
                            v-show="sendLinks.length > INITIAL_RECIPIENTS_SHOWN"
                            @click="onClickedShowMoreRecipients"
                            button-type="link"
                            class="mt-6 mb-8"
                        >
                            {{ t('user.campaign.recipient_load_more') }}
                        </AVButton>
                    </div>
                    <div v-if="store.interpellationMode === 'form'">
                        <div
                            v-if="
                                store.interpellation!.custom_text_before_button
                            "
                            class="mt-2 mb-2 md:max-w-[60%] mx-auto"
                            v-html="
                                store.interpellation!.custom_text_before_button
                            "
                        ></div>
                        <SendButtons
                            :send-links="[
                                {
                                    href: store.interpellation!
                                        .destination_url!,
                                    label: store.interpellation!
                                        .custom_button_text
                                        ? store.interpellation!
                                              .custom_button_text
                                        : t('user.campaign.send_button_form'),
                                },
                            ]"
                            @send-button-clicked="
                                () => {
                                    emit('sendButtonClicked')
                                    isShowingSendMsgOverlay = false
                                }
                            "
                            :campaign-id="props.campaignId"
                        />
                    </div>
                </AVOverlayWhite>
            </CenteredContainer>
        </TransitionExpands>
    </ContainerMain>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useSendCampaignStore } from '@/AppCampaigns/stores'
import { computed, ref } from 'vue'
import { ContentSampleComment } from '@/models/content-samples'
import AVButton from '@/components/forms/AVButton.vue'
import CenteredContainer from '../../CenteredContainer.vue'
import ContainerMain from '../ContainerMain.vue'
import TransitionExpands from '../TransitionExpands.vue'
import { writeTextToClipboard } from '../utils'
import MessagePreview from './MessagePreview.vue'
import ButtonGenerateOther from './ButtonGenerateOther.vue'
import MessageInstructions from './MessageInstructions.vue'
import SendButtons from './SendButtons.vue'
import shuffle from 'lodash/shuffle'
import { recipientDisplay, RecipientPublic } from '@/models/recipients'
import { SendButtonsEmits } from '../../types'
import AVOverlayWhite from '@/components/AVOverlayWhite.vue'

interface Emits extends SendButtonsEmits {}

const emit = defineEmits<Emits>()

interface Props {
    campaignId: string | null
}
const props = defineProps<Props>()

const { t } = useI18n()
const store = useSendCampaignStore(props.campaignId)

const INITIAL_RECIPIENTS_SHOWN = 3
const totalRecipientsShown = ref(INITIAL_RECIPIENTS_SHOWN)

const generatedMessageText = computed(() =>
    store.generatedContent
        ? (store.generatedContent as ContentSampleComment).text
        : null
)

const isShowingSendMsgOverlay = ref(false)

const getCommentLink = (recipient: RecipientPublic) => {
    switch (store.interpellationMode!) {
        case 'facebook':
            return recipient.facebook_page!
        case 'instagram':
            return `https://instagram.com/${recipient.instagram_handle}`
        default:
            return ''
    }
}

const sendLinks = computed(() =>
    shuffle(store.recipients).map((recipient) => ({
        href: getCommentLink(recipient),
        label: recipientDisplay(recipient),
    }))
)

const selectedSendLinks = computed(() =>
    sendLinks.value.slice(0, totalRecipientsShown.value)
)

const getInstructionTKey = () => {
    if (selectedSendLinks.value.length === 1) {
        return 'user.campaign.comment_instructions_single'
    } else {
        return 'user.campaign.comment_instructions'
    }
}

const displayCopiedContentOverlay = (content: string) => {
    writeTextToClipboard(content)
    isShowingSendMsgOverlay.value = true
}

const onClickedShowMoreRecipients = () => {
    totalRecipientsShown.value =
        totalRecipientsShown.value + INITIAL_RECIPIENTS_SHOWN
}

const isSocialMedia = computed(() =>
    ['facebook', 'instagram'].includes(store.interpellationMode!)
)
</script>
