<template>
    <div class="space-y-3">
        <ContainerWhite
            class="border-l-8 border-primary p-2 rounded-lg relative"
        >
            <div class="text-xl font-black mb-2 flex flex-row justify-between">
                <div>
                    {{
                        nameField.value.value!.length
                            ? nameField.value.value
                            : 'Interpellation ' + (interpellationIndex + 1)
                    }}
                </div>
                <div class="w-4/12 pr-2 text-right text-primary cursor-pointer">
                    <i
                        v-if="isInterpellationExpanded"
                        class="fi fi-rs-angle-down translate-y-0.5"
                        @click="onClickExpandCollapseInterpellation"
                    ></i>
                    <i
                        v-else
                        class="fi fi-rs-angle-up translate-y-0.5"
                        @click="onClickExpandCollapseInterpellation"
                    ></i>
                </div>
            </div>
            <div v-if="isInterpellationExpanded">
                <AVInput
                    :label="'Interpellation name'"
                    :placeholder="'Name'"
                    :tooltip-message="'Internal use only'"
                    v-bind="nameField.props.value"
                    v-on="nameField.listeners.value"
                />
                <AVInput
                    :label="'Interpellation description'"
                    :placeholder="'Ex: You\'re about to interpelate company_name'"
                    :tooltip-message="'Will be displayed when users chooses between interpellations'"
                    v-bind="descriptionField.props.value"
                    v-on="descriptionField.listeners.value"
                    class="mt-4"
                />
                <div class="mt-8 flex flex-row justify-end space-x-2">
                    <AVButton
                        @click="onDuplicateInterpellation"
                        button-type="link"
                        class="text-xs"
                    >
                        {{ t('backoffice.actions.duplicate') }}
                        <i class="fi fi-rs-copy"></i>
                    </AVButton>
                    <AVButton
                        v-if="canBeDeleted"
                        @click="onRemoveInterpellation"
                        button-type="link"
                        class="text-xs"
                    >
                        {{ t('backoffice.actions.delete') }}
                        <i class="fi fi-rs-trash"></i>
                    </AVButton>
                </div>
            </div>
        </ContainerWhite>

        <ContainerWhite
            v-if="isInterpellationExpanded"
            class="p-2 rounded-lg space-y-10"
        >
            <div>
                <div class="text-xl font-bold mb-2">
                    {{ t('backoffice.interpellations.recipientsTitle') }}
                </div>
                <div class="text-sm">
                    <div
                        v-for="(
                            recipientCount, interpellationMode
                        ) in recipientStats"
                        :key="interpellationMode"
                    >
                        <span class="capitalize">
                            {{ interpellationMode }} :
                        </span>
                        <span
                            :class="{
                                'text-gray-500': recipientCount > 1,
                                'text-warning': recipientCount <= 1,
                            }"
                        >
                            {{
                                t('backoffice.interpellations.recipientCount', {
                                    n: recipientCount,
                                })
                            }}
                        </span>
                        <span
                            v-if="
                                interpellationMode === 'email' &&
                                recipientCount > 20 &&
                                !isGeoLocated
                            "
                            class="text-warning"
                        >
                            <br />
                            {{
                                t(
                                    'backoffice.interpellations.mailListSizeWarning'
                                )
                            }}
                        </span>
                    </div>
                </div>
            </div>

            <ContainerBoxShadow>
                <div class="text-md font-bold mb-2">
                    {{ t('backoffice.interpellations.selectRListsForm') }}
                </div>
                <RListPicker
                    v-bind="recipientListsField.props.value"
                    v-on="recipientListsField.listeners.value"
                    :geolocated="isGeoLocated"
                />
            </ContainerBoxShadow>

            <ContainerBoxShadow v-if="!isGeoLocated">
                <div class="text-md font-bold mb-2 mt-2">
                    {{ t('backoffice.interpellations.selectRecipientsTitle') }}
                </div>
                <RecipientPicker
                    class="mt-4"
                    v-bind="sendToField.props.value"
                    v-on="sendToField.listeners.value"
                    :label="t('backoffice.interpellations.sendToForm')"
                    placeholder="Select recipients"
                />
                <RecipientPicker
                    v-if="selectedContentSampleTypes.has('email')"
                    class="mt-4"
                    v-bind="ccListField.props.value"
                    v-on="ccListField.listeners.value"
                    :label="t('backoffice.interpellations.ccForm')"
                    placeholder="Select recipients"
                />
                <RecipientPicker
                    v-if="selectedContentSampleTypes.has('email')"
                    class="mt-4"
                    v-bind="bccListField.props.value"
                    v-on="bccListField.listeners.value"
                    :label="t('backoffice.interpellations.bccForm')"
                    placeholder="Select recipients"
                />
            </ContainerBoxShadow>

            <ContainerBoxShadow v-else>
                <span
                    ><i class="fi fi-rs-info mr-1"></i
                    >{{
                        t(
                            'backoffice.campaigns.createUpdateCampaign.infoGeoLocation'
                        )
                    }}</span
                >
            </ContainerBoxShadow>

            <AVError
                v-if="
                    nonFieldValidationErrors?.content_samples &&
                    isInterpellationExpanded
                "
                :error-t-key="nonFieldValidationErrors?.content_samples"
            />

            <AVQuillEditor
                v-if="interpellationModes.includes('form')"
                v-bind="customTextBeforeButtonField.props.value"
                v-on="customTextBeforeButtonField.listeners.value"
                :label="
                    t(
                        'backoffice.campaigns.createUpdateCampaign.buttonDescriptionLabel'
                    )
                "
                :placeholder="
                    t(
                        'backoffice.campaigns.createUpdateCampaign.buttonDescriptionPlaceholder'
                    )
                "
            />

            <AVInput
                v-if="interpellationModes.includes('form')"
                :label="
                    t(
                        'backoffice.campaigns.createUpdateCampaign.buttonTextLabel'
                    )
                "
                :placeholder="
                    t(
                        'backoffice.campaigns.createUpdateCampaign.buttonTextPlaceholder'
                    )
                "
                v-bind="customButtonTextField.props.value"
                v-on="customButtonTextField.listeners.value"
            />

            <AVInput
                v-if="interpellationModes.includes('form')"
                :label="
                    t(
                        'backoffice.campaigns.createUpdateCampaign.destinationURLLabel'
                    )
                "
                :placeholder="
                    t(
                        'backoffice.campaigns.createUpdateCampaign.destinationURLPlaceholder'
                    )
                "
                v-bind="destinationURLField.props.value"
                v-on="destinationURLField.listeners.value"
            />

            <ContentSampleList
                v-if="selectedContentSampleTypes.has('email')"
                content-sample-type="email"
                :interpellation-path="interpellationPath"
                :interpellation-modes="interpellationModes"
            />

            <ContentSampleList
                v-if="selectedContentSampleTypes.has('comment')"
                content-sample-type="comment"
                :interpellation-path="interpellationPath"
                :interpellation-modes="interpellationModes"
            />

            <ContentSampleList
                v-if="selectedContentSampleTypes.has('tweet')"
                content-sample-type="tweet"
                :interpellation-path="interpellationPath"
                :interpellation-modes="interpellationModes"
            />
        </ContainerWhite>

        <AVButton
            button-type="link"
            v-if="!isShowingAdvancedSettings"
            @click="onShowAdvancedSettings"
        >
            Show advanced customization settings
        </AVButton>
        <ContainerWhite v-if="isShowingAdvancedSettings">
            <div class="text-xl font-black mb-2">
                {{ t('backoffice.customQuestions.advancedSettingsTitle') }}
            </div>
            <div v-if="isShowingAdvancedSettings">
                <AVInput
                    v-bind="customPromptField.props.value"
                    v-on="customPromptField.listeners.value"
                    :label="t('backoffice.campaigns.customPrompt')"
                    :placeholder="
                        t('backoffice.campaigns.customPromptPlaceholder')
                    "
                />
            </div>
        </ContainerWhite>
    </div>
</template>
<script setup lang="ts">
import ContainerWhite from '@/AppBackoffice/components/ContainerWhite.vue'
import ContainerBoxShadow from '@/AppBackoffice/components/ContainerBoxShadow.vue'
import ContentSampleList from './ContentSampleList.vue'
import RecipientPicker from '@/AppBackoffice/components/RecipientPicker/RecipientPicker.vue'
import RListPicker from '@/AppBackoffice/components/RListPicker.vue'
import AVButton from '@/components/forms/AVButton.vue'
import AVInput from '@/components/forms/AVInput.vue'
import {
    InterpellationPublic,
    InterpellationWrite,
} from '@/models/interpellations'
import { makeUseField } from '@/utils/forms'
import { CampaignWrite, InterpellationMode } from '@/models/campaigns'
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import AVError from '@/components/forms/AVError.vue'
import { InterpellationNonFieldValidationErrors } from './types'
import { ContentSample } from '@/models/content-samples'
import {
    selectContentSampleTypeForInterpellationMode,
    selectInterpellationModeRecipients,
    selectInterpellationRecipients,
} from '@/models/selectors'
import AVQuillEditor from '@/components/AVQuillEditor.vue'

interface Props {
    interpellation: InterpellationPublic
    interpellationIndex: number
    interpellationPath: `interpellations.${number}`
    interpellationModes: Array<InterpellationMode>
    nonFieldValidationErrors?: InterpellationNonFieldValidationErrors
    canBeDeleted: boolean
    isGeoLocated: boolean
}

interface Emits {
    (event: 'removeInterpellation'): void
    (event: 'duplicateInterpellation'): void
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const { t } = useI18n()
const isInterpellationExpanded = ref(true)
const selectedContentSampleTypes = computed<Set<ContentSample['type']>>(
    () =>
        new Set(
            props.interpellationModes.map((mode) =>
                selectContentSampleTypeForInterpellationMode(mode)
            )
        )
)

const useInterpellationField = makeUseField<CampaignWrite, InterpellationWrite>(
    props.interpellationPath
)
const nameField = useInterpellationField('name')
const descriptionField = useInterpellationField('description')
const recipientListsField = useInterpellationField('recipient_lists')
const sendToField = useInterpellationField('send_to')
const ccListField = useInterpellationField('cc_list')
const bccListField = useInterpellationField('bcc_list')
const customPromptField = useInterpellationField('custom_interpellation_prompt')
const destinationURLField = useInterpellationField('destination_url')
const customButtonTextField = useInterpellationField('custom_button_text')
const customTextBeforeButtonField = useInterpellationField(
    'custom_text_before_button'
)

const recipientStats = computed(() =>
    Object.fromEntries(
        props.interpellationModes.map((mode) => [
            mode,
            selectInterpellationModeRecipients(
                selectInterpellationRecipients(props.interpellation),
                mode
            ).length,
        ])
    )
)

const onClickExpandCollapseInterpellation = () => {
    isInterpellationExpanded.value = !isInterpellationExpanded.value
}
const isShowingAdvancedSettings = ref(false)
const onShowAdvancedSettings = () => {
    isShowingAdvancedSettings.value = true
}
const onRemoveInterpellation = () => emit('removeInterpellation')
const onDuplicateInterpellation = () => emit('duplicateInterpellation')
</script>
