<template>
    <div
        class="input-group"
        :class="{ 'has-errors': errorMessage || errorTKey }"
    >
        <label
            :class="{
                'block mb-2 text-sm font-normal text-gray-900': true,
                'block mb-2 text-sm font-medium text-red-700 ':
                    errorMessage || errorTKey,
            }"
            v-if="label"
            >{{ label }}</label
        >
        <Multiselect
            v-model="recipients"
            :options="options"
            :multiple="true"
            :taggable="true"
            @tag="onAddNewRecipientEmail"
            @select="onExistingRecipientAdded"
            @remove="onRecipientRemoved"
            :loading="crudStore.isBusy"
            :hide-selected="true"
            :tag-placeholder="t('backoffice.recipients.recipientsPickerAddNew')"
            :select-label="t('backoffice.recipients.recipientsPickerAddToList')"
            placeholder=""
            @search-change="onSearchQueryChanged"
            label="email"
            track-by="id"
        >
            <template #selection="{ values, isOpen }">
                <!-- Since, we completely replace the list of tags by our own component,
                 we need to hide the default list of tags displayed by vue-multiselect -->
                <span v-show="!isOpen">{{ values.length }} recipients</span>
            </template>
        </Multiselect>

        <div class="flex flex-wrap gap-2 my-3">
            <div
                v-for="(recipient, idx) in recipients"
                :key="idx"
                class="flex items-center border-[0.5px] border-neutral-400 rounded-lg px-3 py-1 relative group hover:bg-blue-50"
            >
                <span class="text-sm text-gray-700">{{
                    _recipientDisplay(recipient)
                }}</span>
                <button
                    class="ml-2 text-gray-400 hover:text-gray-600 translate-y-0.3"
                    @click.stop.prevent="removeRecipient(recipient)"
                >
                    <i class="fi fi-rs-cross text-xs"></i>
                </button>
                <RecipientTooltip :recipient="recipient" />
            </div>
        </div>

        <p class="mt-2 text-sm text-red-600" v-if="errorMessage || errorTKey">
            {{ errorTKey ? t(errorTKey) : errorMessage }}
        </p>
    </div>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue'
import {
    useRecipientPickerStore,
    useRecipientsBackofficeCrudStore,
} from '@/AppBackoffice/recipients/stores'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import { ValidationError } from 'yup'
import { useI18n } from 'vue-i18n'
import { RecipientSimple, RecipientWrite } from '@/models/recipients'
import { FormFieldEmits, FormFieldProps } from '@/utils/forms'
import { recipientValidator } from '../../recipients/validation'
import RecipientTooltip from './RecipientTooltip.vue'
import { onBeforeMount } from 'vue'

interface Props extends FormFieldProps<Array<RecipientSimple>> {
    placeholder?: string
}

interface Emits extends FormFieldEmits<Array<RecipientSimple>> {}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const recipientPickerStore = useRecipientPickerStore()
const crudStore = useRecipientsBackofficeCrudStore()
const searchQuery = ref('')
const recipients = ref<Array<RecipientSimple>>(
    (props.modelValue || []).slice(0)
)
const errorMessage = ref<null | string>(null)
const { t } = useI18n()

const onSearchQueryChanged = (query: string) => {
    searchQuery.value = query
}

const options = computed(() =>
    recipientPickerStore.recipients
        ? recipientPickerStore.recipients.filter(
              (r) =>
                  (searchQuery.value.length === 0 ||
                      r.email.includes(searchQuery.value)) &&
                  !recipients.value.find(
                      (selectedRecipient) => selectedRecipient.id === r.id
                  )
          )
        : []
)

const removeRecipient = (recipient: RecipientSimple) => {
    recipients.value = recipients.value.filter((r) => r.id !== recipient.id)
    onRecipientRemoved()
}

const onAddNewRecipientEmail = async (email: string) => {
    let newRecipient: RecipientWrite = {
        email,
    }
    try {
        newRecipient = recipientValidator.validateSync(newRecipient)
    } catch (error) {
        if (error instanceof ValidationError && error.path === 'email') {
            errorMessage.value = error.message
            return
        } else {
            throw error
        }
    }
    errorMessage.value = null

    await crudStore.runCreate(newRecipient)
    const createdRecipient = crudStore.itemData
    if (
        !createdRecipient ||
        createdRecipient.email.toLowerCase() !== email.toLowerCase()
    ) {
        throw new Error('Unexpected error creating recipient')
    }

    recipients.value.push({ ...createdRecipient, geo_id: null, geo_type: null })
    onExistingRecipientAdded()
    await recipientPickerStore.listRecipients()
}

const onExistingRecipientAdded = () => {
    emit('update:modelValue', recipients.value)
}

const onRecipientRemoved = () => {
    emit('update:modelValue', recipients.value)
}

const _recipientDisplay = (recipient: RecipientSimple) => {
    if (recipient.first_name || recipient.last_name) {
        return `${recipient.first_name} ${recipient.last_name}`
    } else {
        return recipient.email
    }
}

onBeforeMount(async () => {
    await recipientPickerStore.listRecipients()
})
</script>
