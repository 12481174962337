<template>
    <form @submit.prevent="onSubmit" class="space-y-8">
        <ContainerWhite class="border-l-8">
            <div class="text-xl font-black mb-2">Content of the messages</div>
            <div class="text-sm text-gray-500 text-thin">
                Add as many interpellations and content samples as you wish
            </div>
        </ContainerWhite>
        <InterpellationFields
            v-for="(
                interpellation, interpellationIndex
            ) in campaign.interpellations"
            :key="interpellationIndex"
            :interpellation="interpellation"
            :interpellation-path="`interpellations.${interpellationIndex}`"
            :interpellation-index="interpellationIndex"
            :interpellation-modes="campaign.interpellation_modes"
            :can-be-deleted="campaign.interpellations.length > 1"
            :non-field-validation-errors="
                nonFieldValidationErrors.get(interpellationIndex)
            "
            @remove-interpellation="onRemoveInterpellation(interpellationIndex)"
            @duplicate-interpellation="
                onDuplicateInterpellation(interpellationIndex)
            "
            :is-geo-located="campaign.is_geo_located || false"
        />
        <div class="p-8 lg:px-4 bg-white rounded-lg text-center">
            <AVButton
                class="text-primary text-center text-md cursor-pointer"
                button-type="link"
                @click="onAddInterpellation"
            >
                <i class="fi fi-rs-add"></i>
                {{ t('backoffice.interpellations.addInterpellation') }}
            </AVButton>
        </div>
        <SaveButtons
            next-button-t-key="backoffice.actions.saveQuit"
            @save="onSave"
            @previous="onClickPreviousStep"
            :has-previous="true"
        />
    </form>
</template>
<script setup lang="ts">
import { RequestStatus } from '@/types'
import cloneDeepWith from 'lodash/cloneDeepWith'
import omit from 'lodash/omit'
import ContainerWhite from '@/AppBackoffice/components/ContainerWhite.vue'
import {
    useCampaignBackofficeCrudStore,
    useCampaignCreateUpdateWizardStore,
} from '@/AppBackoffice/campaigns/stores'
import InterpellationFields from './InterpellationFields.vue'
import SaveButtons from '../components/SaveButtons.vue'
import AVButton from '@/components/forms/AVButton.vue'
import { useRListsBackofficeCrudStore } from '@/AppBackoffice/rlists/stores'
import { onMounted, ref, watch } from 'vue'
import { CampaignWrite } from '@/models/campaigns'
import { useForm } from 'vee-validate'
import { makeUseField } from '@/utils/forms'
import {
    campaignStepInterpellationsValidator,
    validateInterpellationContentSamplesInterpellationModes,
} from './validation'
import { InterpellationNonFieldValidationErrors } from './types'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { usePredefinedToasts } from '../utils'

const predefinedToasts = usePredefinedToasts()

interface Props {
    campaignId: string
}

const props = defineProps<Props>()

const router = useRouter()
const { t } = useI18n()
const rlistsStore = useRListsBackofficeCrudStore()
const crudStore = useCampaignBackofficeCrudStore()
const wizardStore = useCampaignCreateUpdateWizardStore()
const nonFieldValidationErrors = ref<
    Map<number, InterpellationNonFieldValidationErrors>
>(new Map())
const {
    values: campaign,
    validate,
    meta,
    setErrors,
} = useForm({
    initialValues: crudStore.itemData,
    validationSchema: campaignStepInterpellationsValidator,
})
const useCampaignField = makeUseField<CampaignWrite>()
const interpellationField = useCampaignField('interpellations')

const onRemoveInterpellation = (index: number) => {
    interpellationField.value.value!.splice(index, 1)
}

const onAddInterpellation = () => {
    interpellationField.value.value = interpellationField.value.value || []
    interpellationField.value.value.push({
        name: '',
        send_to: [],
        cc_list: [],
        bcc_list: [],
        content_samples: [],
        recipient_lists: [],
        custom_text_before_button: '',
    })
}

const onClickPreviousStep = async () => {
    const wasSaved = await onSave()
    if (wasSaved) {
        wizardStore.goToPreviousStep()
    }
}

const onSave = async () => {
    let { valid: isValid } = await validate()

    nonFieldValidationErrors.value = new Map()
    campaign.interpellations.forEach((interpellation, interpellationIndex) => {
        const areContentSamplesValid =
            validateInterpellationContentSamplesInterpellationModes(
                interpellation.content_samples,
                campaign.interpellation_modes
            )
        if (!areContentSamplesValid) {
            isValid = isValid && false
            nonFieldValidationErrors.value.set(interpellationIndex, {
                content_samples:
                    'backoffice.campaigns.errors.noInterpellationModeAvailable',
            })
        }
    })
    if (!isValid) {
        predefinedToasts.error()
        return false
    }

    const requestStatus = await crudStore.runUpdate(props.campaignId, campaign)

    if (requestStatus === RequestStatus.SUCCESS) {
        wizardStore.setDirtyState(false)
        predefinedToasts.success()
    }

    return requestStatus
}

const onSubmit = async () => {
    if ((await onSave()) === RequestStatus.SUCCESS) {
        router.push({
            name: 'campaigns_list',
        })
    }
}

const onDuplicateInterpellation = (interpellationIndex: number) => {
    const duplicatedInterpellation = cloneDeepWith(
        campaign.interpellations[interpellationIndex],
        (value) => omit(value, ['id', 'created_date', 'updated_date'])
    )
    duplicatedInterpellation.name = duplicatedInterpellation.name + ' (copy)'
    interpellationField.value.value!.splice(
        interpellationIndex + 1,
        0,
        duplicatedInterpellation
    )
    duplicatedInterpellation.recipient_lists = [
        ...duplicatedInterpellation.recipient_lists,
    ]
}

onMounted(() => {
    rlistsStore.runList()
    if (campaign.interpellations.length === 0) {
        onAddInterpellation()
    }
})

const nonFieldErrors = ref<string | null>(null)
watch(
    () => crudStore.itemValidationErrors,
    (backendValidationErrors) => {
        if (backendValidationErrors) {
            const { non_field_errors, ...fieldErrors } = backendValidationErrors
            setErrors(fieldErrors)
            if (non_field_errors) {
                nonFieldErrors.value = non_field_errors.join(', ')
            }
        }
    }
)
watch(() => meta.value.dirty, wizardStore.setDirtyState)
</script>
