<template>
    <StepContainer
        class="relative md:space-y-8 px-4 md:px-0"
        :campaign-id="props.campaignId"
    >
        <StepTitle
            class="mb-4"
            :titles="[
                {
                    title: t('user.email.StepReenterTitle'),
                },
            ]"
        />

        <StepContainerInner2 v-if="store.campaign?.is_geo_located" class="mb-6">
            <GeolocationSelection
                instruction-t-key="user.campaign.geolocation_reenter"
                :campaign-id="props.campaignId"
            />
        </StepContainerInner2>

        <div v-else>
            <StepContainerInner2
                v-if="otherInterpellations.length > 0"
                class="mb-6"
            >
                <StepInstructions class="font-[600]">
                    {{ t('user.campaign.select_interpellation_reenter') }}
                </StepInstructions>
                <InterpellationSelect
                    placeholder-t-key="user.forms.select_an_option"
                    button-t-key="user.actions.select"
                    @submit-interpellation="onSubmitSelectedInterpellation"
                    :interpellation-options="otherInterpellations"
                />
            </StepContainerInner2>

            <StepContainerInner2
                v-if="otherInterpellationModes.length > 0"
                class="mb-6"
            >
                <StepInstructions class="font-[600]">
                    {{
                        t('user.campaign.select_interpellation_mode_reenter', {
                            rlistName: store.interpellation!.name,
                        })
                    }}
                </StepInstructions>
                <InterpellationModesButtonList
                    :custom-form-button-text="
                        store.interpellation!.custom_button_text
                            ? store.interpellation!.custom_button_text
                            : t('user.campaign.interpellation_mode.form')
                    "
                    :interpellationModesAvailable="otherInterpellationModes"
                    @choose-interpellation-mode="onChooseInterpellationMode"
                />
            </StepContainerInner2>
        </div>

        <StepContainerInner2 v-if="sharingUrl" class="mb-6">
            <StepInstructions class="font-[600]">
                {{ t('user.campaign.share_on_social_media') }}
            </StepInstructions>
            <div
                class="flex flex-row w-full md:w-auto md:justify-between space-x-8"
            >
                <ShareButton
                    interpellation-mode="twitter"
                    :href="`https://twitter.com/intent/tweet?url=${encodeURIComponent(sharingUrl)}`"
                />
                <ShareButton
                    interpellation-mode="facebook"
                    :href="`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(sharingUrl)}`"
                />

                <ShareButton
                    interpellation-mode="twitter"
                    :to-copy="sharingUrl"
                />
            </div>
        </StepContainerInner2>
    </StepContainer>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import {
    useSendCampaignStore,
    useSendCampaignWizardStore,
} from '@/AppCampaigns/stores'
import StepTitle from '../StepTitle.vue'
import { computed, onBeforeMount } from 'vue'
import ShareButton from './ShareButton.vue'
import StepContainer from '../StepContainer.vue'
import StepContainerInner2 from '../StepContainerInner2.vue'
import StepInstructions from '../StepInstructions.vue'
import InterpellationSelect from '../StepInterpellationSelection/InterpellationSelect.vue'
import InterpellationModesButtonList from '../StepInterpellationModeSelection/InterpellationModesButtonList.vue'
import { InterpellationMode } from '@/models/campaigns'
import { API_ROOT_URL } from '@/constants'
import trimEnd from 'lodash/trimEnd'
import GeolocationSelection from '../StepGeolocation/GeolocationSelection.vue'

interface Props {
    campaignId: string | null
}
const props = defineProps<Props>()

const { t } = useI18n()
const store = useSendCampaignStore(props.campaignId)
const wizardStore = useSendCampaignWizardStore(props.campaignId)

const onSubmitSelectedInterpellation = (
    selectedInterpellationIndex: number | null
) => {
    wizardStore.$reset(null, 'StepInterpellationSelection')
    if (selectedInterpellationIndex !== null) {
        store.setInterpellation(selectedInterpellationIndex)
        wizardStore.goToNextStep()
    }
}

const onChooseInterpellationMode = (interpellationMode: InterpellationMode) => {
    wizardStore.$reset(null, 'StepInterpellationModeSelection')
    store.setInterpellationMode(interpellationMode)
    wizardStore.goToNextStep()
}

const sharingUrl = computed(() =>
    store.campaign!.sharing_url
        ? store.campaign!.sharing_url.startsWith('http')
            ? store.campaign!.sharing_url
            : trimEnd(API_ROOT_URL, '/') + store.campaign!.sharing_url
        : null
)

const otherInterpellations = computed<Array<{ label: string; value: number }>>(
    () => {
        if (store.campaign?.interpellations) {
            return store
                .campaign!.interpellations.map((interpellation, index) =>
                    store.availableInterpellationAndModes[interpellation.id!]
                        .length > 0
                        ? {
                              label: interpellation.name,
                              value: index,
                              id: interpellation.id,
                          }
                        : null
                )
                .filter((i) => i !== null)
                .filter(
                    (interpellation) =>
                        interpellation!.id != store.lastInterpellationId
                ) as Array<{
                label: string
                value: number
            }>
        }
        return []
    }
)

const otherInterpellationModes = computed(
    () =>
        store.availableInterpellationAndModes[store.lastInterpellationId!] || []
)

onBeforeMount(async () => {
    if (!store.campaign) {
        throw new Error('Store should have a campaign set')
    }
    if (!store.interpellation) {
        throw new Error('Store should have an interpellation set')
    }
})
</script>
