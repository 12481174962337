export interface AddressSearchResults {
    features: Array<{
        type: 'Feature'
        geometry: {
            type: string
            coordinates: [number, number]
        }
        properties: {
            label: string
            street?: string
            postcode: string
        }
    }>
}

export enum GeolocState {
    START = 'START',
    VALIDATE_ADDRESS = 'VALIDATE_ADDRESS',
    SELECT_RECIPIENT = 'SELECT_RECIPIENT',
    ENDED = 'ENDED',
}
