import { defineStore } from 'pinia'
import sample from 'lodash/sample'
import { Locale } from '@/i18n'
import { v4 as uuid4 } from 'uuid'
import {
    findGeolocRequest,
    openGenerateContentStream,
    readGenerateContentStream,
    searchAddressRequest,
    updateSentStatus,
} from './SendCampaign/requests'
import { useRequestTracker } from '@/utils/request-tracker'
import { Sender, StreamingStatus } from './SendCampaign/types'
import { SEND_CAMPAIGN_STEP_DEFINITIONS } from './SendCampaign/constants'
import { InterpellationMode, CampaignPublic } from '@/models/campaigns'
import { ContentSample, defaultContentSample } from '@/models/content-samples'
import { InterpellationPublic } from '@/models/interpellations'
import { CustomQuestion } from '@/models/custom-questions'
import { defineWizardStore } from '@/stores/wizard'
import { computed, ref } from 'vue'
import { HttpStatusCode } from 'axios'
import {
    areSameRecipients,
    RecipientPublic,
    RecipientSimple,
} from '@/models/recipients'
import {
    selectContentSampleType,
    selectContentSamplesForInterpellationMode,
    selectInterpellationModeRecipients,
    selectInterpellationModesAvailable,
    selectInterpellationRecipients,
} from '@/models/selectors'
import { readCampaignPublic } from './requests'
import { useI18n } from 'vue-i18n'
import { AddressSearchResults, GeolocState } from './types'
import { ModelId } from '@/models/types'
import { loadColors } from '@/services/colors'
import { createGreatingsSamplesForRecipient } from './helpers'
import { components } from '@/schema'

type StepKey = keyof typeof SEND_CAMPAIGN_STEP_DEFINITIONS

export const useSendCampaignWizardStore = (prefix: string | null = null) => {
    const fullPrefix = prefix ? `${prefix}_` : ''
    return defineWizardStore(
        `${fullPrefix}SendCampaign:wizard`,
        SEND_CAMPAIGN_STEP_DEFINITIONS
    )()
}

export const useLoadCampaignStore = (prefix: string | null = null) => {
    const fullPrefix = prefix ? `${prefix}_` : ''
    return defineStore(`${fullPrefix}LoadCampaign`, () => {
        const requestTracker = useRequestTracker<CampaignPublic>()
        const { t } = useI18n()

        // STATE
        const campaignData = ref<CampaignPublic | null>(null)

        // ACTIONS
        const setCampaignData = (data: CampaignPublic) =>
            (campaignData.value = data)

        const loadCampaign = async (campaignId: string) =>
            requestTracker.runRequest(readCampaignPublic, HttpStatusCode.Ok, [
                campaignId,
            ])
        const updateColors = async (element: ShadowRoot | null = null) => {
            loadColors(
                campaign.value?.color_primary,
                campaign.value?.color_secondary,
                campaign.value?.color_accent,
                element
            )
        }

        // GETTERS
        const campaign = computed(
            () => campaignData.value || requestTracker.getLoadedData()
        )
        const isLoading = computed(requestTracker.isRequestInProgress)
        const loadingError = computed(() => requestTracker.state.value.error)

        const title = computed(() => {
            if (campaign.value) {
                return campaign.value.name
            } else if (loadingError.value) {
                return t('embed.loadingError.title')
            } else {
                return null
            }
        })

        return {
            // STATE
            _requestTracker: requestTracker.state,
            // ACTIONS
            loadCampaign,
            setCampaignData,
            updateColors,
            // GETTERS
            campaign,
            isLoading,
            loadingError,
            title,
        }
    })()
}

export const useRecipientSearchStore = (prefix: string | null = null) => {
    const fullPrefix = prefix ? `${prefix}_` : ''
    return defineStore(
        `${fullPrefix}AddressSearch`,
        () => {
            const addressSearchRequestTracker =
                useRequestTracker<AddressSearchResults>()
            const findGeolocationRequestTracker =
                useRequestTracker<Array<components['schemas']['Geolocation']>>()
            const selectedAddressIndex = ref<undefined | number>(undefined)
            const rlistIdsRef = ref<Array<ModelId>>([])

            // ACTIONS
            const searchAddress = async (
                query: string,
                rlistIds: Array<ModelId>,
                geoType: string,
                strict: boolean
            ) => {
                rlistIdsRef.value = rlistIds
                await addressSearchRequestTracker.runRequest(
                    searchAddressRequest,
                    HttpStatusCode.Ok,
                    [query]
                )
                if (addressSearchResults.value.length === 1) {
                    selectAddress(0, geoType, strict)
                }
            }

            const searchDestinataries = async (
                latLgn: [number, number],
                geoType: string,
                strict: boolean
            ) => {
                return findGeolocationRequestTracker.runRequest(
                    findGeolocRequest,
                    HttpStatusCode.Ok,
                    [latLgn, geoType, strict]
                )
            }
            const selectAddress = (
                selection: number,
                geoType: string,
                strict: boolean
            ) => {
                if (selection < addressSearchResults.value.length) {
                    selectedAddressIndex.value = selection
                    searchDestinataries(
                        selectedAddressCoordinates.value!,
                        geoType,
                        strict
                    )
                }
            }

            const reset = () => {
                resetSearch()
            }

            const resetSearch = () => {
                selectedAddressIndex.value = undefined
                addressSearchRequestTracker.reset()
                findGeolocationRequestTracker.reset()
            }

            // GETTERS
            const addressSearchResults = computed(() => {
                const results = addressSearchRequestTracker.getLoadedData()
                return results
                    ? results.features.filter(
                          (f) => f.geometry.type === 'Point'
                      )
                    : []
            })

            const searchState = computed(() => {
                if (addressSearchResults.value.length === 0) {
                    return GeolocState.START
                }
                if (
                    addressSearchResults.value.length !== 0 &&
                    selectedAddressIndex.value === undefined
                ) {
                    return GeolocState.VALIDATE_ADDRESS
                }
                if (
                    addressSearchResults.value.length !== 0 &&
                    selectedAddressIndex.value !== undefined
                ) {
                    return GeolocState.SELECT_RECIPIENT
                }
                return GeolocState.ENDED
            })

            const selectedAddress = computed(() => {
                if (
                    addressSearchResults.value.length > 0 &&
                    selectedAddressIndex.value !== undefined
                ) {
                    return addressSearchResults.value[
                        selectedAddressIndex.value
                    ]
                }
                return null
            })
            const selectedAddressCoordinates = computed(() => {
                if (selectedAddress.value) {
                    return selectedAddress.value.geometry.coordinates
                }
                return null
            })
            const geoLocationSearchResults = computed(
                findGeolocationRequestTracker.getLoadedData
            )

            const isLoading = computed(
                () =>
                    addressSearchRequestTracker.isRequestInProgress() ||
                    findGeolocationRequestTracker.isRequestInProgress()
            )

            const hasErrored = computed(
                () =>
                    !!(
                        addressSearchRequestTracker.state.value.error ||
                        findGeolocationRequestTracker.state.value.error
                    )
            )

            return {
                // STATE
                _requestTracker: addressSearchRequestTracker.state,
                geolocationTracker: findGeolocationRequestTracker.state,
                selectedAddressIndex,
                // ACTIONS
                searchAddress,
                searchDestinataries,
                selectAddress,
                reset,
                // GETTERS
                selectedAddressCoordinates,
                searchState,
                selectedAddress,
                addressSearchResults,
                geoLocationSearchResults,
                isLoading,
                hasErrored,
            }
        },
        {
            persist: {
                paths: [
                    '_requestTracker',
                    'selectedAddressIndex',
                    'geolocationTracker',
                ],
            },
        }
    )()
}

export const useSendCampaignStore = (prefix: string | null = null) => {
    const fullPrefix = prefix ? `${prefix}_` : ''
    return defineStore(
        `${fullPrefix}SendCampaign`,
        () => {
            const wizardStore = useSendCampaignWizardStore(prefix)
            const { t } = useI18n()

            // STATE
            const campaign = ref<CampaignPublic | CampaignPublic | null>(null)
            const recipient = ref<RecipientPublic | null>(null)
            const interpellationIndex = ref<number | null>(null)
            const interpellationMode = ref<InterpellationMode | null>(null)
            const streamStatus = ref<StreamingStatus>(StreamingStatus.INIT)
            const sender = ref<Sender | null>(null)
            const shouldCustomizeEmail = ref<boolean>(true)
            const generatedContent = ref<ContentSample | null>(null)
            const generatedContentId = ref<string | null>(null)
            const customQuestionsAnswers = ref<Array<string>>([])
            const isSendDone = ref<boolean>(false)
            const interpellationModesAvailable = ref<Array<InterpellationMode>>(
                []
            )
            const sentInterpellationsAndModes = ref<
                Record<string, Array<InterpellationMode>>
            >({})
            const lastInterpellationId = ref<string | null>(null)
            const errorHappened = ref<boolean>(false)

            // ACTIONS
            const setCampaign = (
                newCampaign: CampaignPublic | CampaignPublic
            ) => {
                campaign.value = newCampaign
                if (newCampaign.interpellations.length === 1) {
                    setInterpellation(0)
                } else if (newCampaign.interpellations.length === 0) {
                    throw new Error(
                        'Campaign must have at least one interpellation'
                    )
                }
                _refreshWizardSteps()
            }

            const setRecipient = (newRecipient: RecipientPublic) => {
                if (!campaign.value) {
                    throw new Error('Campaign must be set before recipient')
                }
                recipient.value = newRecipient
                // TODO : email is used here as a unique identifier, but what if there's no email ?
                // -> same in `selectInterpellationRecipients`
                const interpellationIndex =
                    campaign.value.interpellations.findIndex(
                        (interpellation) =>
                            !!selectInterpellationRecipients(
                                interpellation
                            ).find((recipient) =>
                                areSameRecipients(recipient, newRecipient)
                            )
                    )
                if (interpellationIndex === -1) {
                    throw new Error(
                        `Recipient ${newRecipient.email} not found in campaign`
                    )
                }
                setInterpellation(interpellationIndex)
            }

            const setInterpellation = (index: number) => {
                setIsSendDone(false)
                interpellationIndex.value = index
                shouldCustomizeEmail.value = true
                if (!campaign.value) {
                    throw new Error(
                        'Campaign must be set before interpellation'
                    )
                }
                if (!interpellation.value) {
                    throw new Error('Interpellation not found in campaign')
                }
                setInterpellationModesAvailable()
                if (interpellationModesAvailable.value.length === 1) {
                    setInterpellationMode(interpellationModesAvailable.value[0])
                } else if (interpellationModesAvailable.value.length === 0) {
                    throw new Error(
                        `No interpellation mode available for interpellation ${interpellation.value.id}`
                    )
                }
                _refreshWizardSteps()
            }

            const destinatariesInCampaign = (
                arrayToFilter: Array<RecipientSimple>
            ) => {
                const recipients = campaign
                    .value!.interpellations.map(selectInterpellationRecipients)
                    .flat()
                    .map((recipient) => recipient.email)
                return arrayToFilter.filter((newRecipient) => {
                    return recipients.includes(newRecipient.email)
                })
            }

            const setInterpellationMode = (mode: InterpellationMode | null) => {
                setIsSendDone(false)
                interpellationMode.value = mode
                shouldCustomizeEmail.value = true
                _refreshWizardSteps()
            }

            const setStreamStatus = (status: StreamingStatus) => {
                streamStatus.value = status
            }

            const setSender = (
                newSender: Sender | null,
                newShouldCustomizeEmail: boolean
            ) => {
                sender.value = newSender
                shouldCustomizeEmail.value = newShouldCustomizeEmail
            }

            const $reset = () => {
                isSendDone.value = false
                campaign.value = null
                interpellationIndex.value = null
                setInterpellationModesAvailable()
                streamStatus.value = StreamingStatus.INIT
                // sender.value = null
                shouldCustomizeEmail.value = true
                generatedContent.value = null
                customQuestionsAnswers.value = []
                wizardStore.$reset()
            }

            const pickNonCustomizedGeneratedContent = () => {
                const contentSamples =
                    selectContentSamplesForInterpellationMode(
                        interpellation.value!,
                        interpellationMode.value!
                    )
                const randomContentSample = sample(contentSamples)
                if (!randomContentSample) {
                    throw new Error(
                        'No content sample found for interpellation mode'
                    )
                }
                generatedContent.value = randomContentSample
            }

            const reformulateContentSample = async () => {
                errorHappened.value = false
                if (!interpellation.value && !interpellationMode.value) {
                    throw new Error(
                        'Interpellation and interpellation mode must be set before reformulation'
                    )
                }
                const contentSampleType = selectContentSampleType(
                    interpellationMode.value!
                )
                const selectedContentSamples =
                    interpellation.value!.content_samples.filter(
                        (contentSample) =>
                            contentSample.type === contentSampleType
                    )
                generatedContent.value = defaultContentSample(contentSampleType)
                generatedContentId.value = uuid4()

                setStreamStatus(StreamingStatus.STARTING)
                try {
                    const response = await openGenerateContentStream({
                        interpellation_id: interpellation.value
                            ? interpellation.value.id
                            : undefined,
                        generated_content_id: generatedContentId.value,
                        content_samples: selectedContentSamples,
                        custom_questions: campaign.value!.custom_questions,
                        custom_questions_answers: customQuestionsAnswers.value,
                        custom_prompt: campaign.value!.custom_prompt,
                        custom_interpellation_prompt: interpellation.value
                            ? interpellation.value.custom_interpellation_prompt
                            : '',
                        language: language.value,
                        interpellation_mode: interpellationMode.value!,
                        greetings:
                            recipient.value && campaign.value?.is_geo_located
                                ? t(
                                      createGreatingsSamplesForRecipient(
                                          recipient.value
                                      ),
                                      {
                                          FIRST_NAME:
                                              recipient.value.first_name,
                                          LAST_NAME: recipient.value.last_name,
                                      }
                                  )
                                : '',
                    })
                    if (response.status !== HttpStatusCode.Ok) {
                        setStreamStatus(StreamingStatus.ERROR)
                        return
                    }

                    const stream = readGenerateContentStream(response)

                    setStreamStatus(StreamingStatus.IN_PROGRESS)
                    try {
                        while (true) {
                            const { done, value } = await stream.next()
                            if (done) {
                                break
                            }
                            if (value instanceof Error) {
                                throw value
                            }
                            if (!value) {
                                continue
                            }

                            Object.assign(generatedContent.value, value)
                        }
                    } catch (error) {
                        // TODO An error occurred in manageStream
                        pickNonCustomizedGeneratedContent()
                        setStreamStatus(StreamingStatus.ABORTED)
                    }
                    setStreamStatus(StreamingStatus.SUCCESS)
                } catch (error) {
                    pickNonCustomizedGeneratedContent()
                    setStreamStatus(StreamingStatus.ABORTED)
                    return
                }
            }

            const setIsSendDone = (value: boolean) => {
                if (value) {
                    const interpellation =
                        campaign.value!.interpellations[
                            interpellationIndex.value!
                        ]
                    if (sentInterpellationsAndModes.value[interpellation.id!]) {
                        sentInterpellationsAndModes.value[interpellation.id!] =
                            [
                                ...sentInterpellationsAndModes.value[
                                    interpellation.id!
                                ],
                                interpellationMode.value!,
                            ]
                    } else {
                        sentInterpellationsAndModes.value[interpellation.id!] =
                            [interpellationMode.value!]
                    }
                    lastInterpellationId.value = interpellation.id!
                }
                isSendDone.value = value
            }

            const _refreshWizardSteps = () => {
                const selectedStepsKeys: Array<StepKey> = [
                    campaign.value!.is_geo_located
                        ? 'StepGeolocation'
                        : campaign.value!.interpellations.length > 1
                          ? 'StepInterpellationSelection'
                          : null,
                    interpellationModesAvailable.value.length > 1
                        ? 'StepInterpellationModeSelection'
                        : null,
                    // With email we always ask names, so there's always the `StepSenderQuestions` step.
                    ['email', null].includes(interpellationMode.value) ||
                    customQuestions.value.length
                        ? 'StepSenderQuestions'
                        : null,
                    'StepGenerateMessage',
                ].filter((stepKey): stepKey is StepKey => !!stepKey)
                wizardStore.setSelectedStepsKeys(
                    selectedStepsKeys,
                    wizardStore.currentStepKey
                )
            }

            const markContentAsSent = async () => {
                if (generatedContentId.value) {
                    updateSentStatus({
                        content_id: generatedContentId.value!,
                        content_sent: true,
                    })
                }
            }

            const setInterpellationModesAvailable = () => {
                interpellationModesAvailable.value = campaign.value
                    ? interpellation.value
                        ? selectInterpellationModesAvailable(
                              campaign.value,
                              interpellation.value,
                              selectInterpellationRecipients(
                                  interpellation.value
                              )
                          )
                        : campaign.value.interpellation_modes
                    : []
            }

            // GETTERS
            const campaignGeoType = computed(() => {
                if (!campaign.value) {
                    throw new Error('Campaign must be set')
                }
                return campaign.value.interpellations
                    .map(selectInterpellationRecipients)
                    .flat()
                    .map((recipient) => recipient.geo_type)
                    .filter((geoType) => geoType !== null)[0]
            })

            const language = computed((): Locale => {
                return campaign.value
                    ? (campaign.value.language as Locale)
                    : 'en'
            })

            const isStreamingIdle = computed((): boolean => {
                return ![
                    StreamingStatus.STARTING,
                    StreamingStatus.IN_PROGRESS,
                ].includes(streamStatus.value)
            })

            const interpellation = computed((): InterpellationPublic | null => {
                if (
                    !campaign.value ||
                    interpellationIndex.value === null ||
                    !campaign.value.interpellations
                ) {
                    return null
                }
                const interpellation =
                    campaign.value.interpellations[interpellationIndex.value]
                if (!interpellation) {
                    throw new Error('Interpellation not found in campaign')
                }
                return interpellation
            })

            const interpellationModesAvailableForSelectedRecipients = computed(
                () =>
                    campaign.value
                        ? interpellation.value
                            ? selectInterpellationModesAvailable(
                                  campaign.value,
                                  interpellation.value,
                                  recipient.value
                                      ? [recipient.value]
                                      : selectInterpellationRecipients(
                                            interpellation.value
                                        )
                              ).filter((mode) =>
                                  availableInterpellationAndModes.value[
                                      interpellation.value!.id!
                                  ].includes(mode)
                              )
                            : campaign.value.interpellation_modes
                        : []
            )

            const mappingInterpellationsAndModes = computed(() => {
                if (campaign.value) {
                    return campaign.value.interpellations.reduce(
                        (
                            object: Record<string, Array<InterpellationMode>>,
                            interpellation
                        ) => {
                            object[interpellation.id!] =
                                selectInterpellationModesAvailable(
                                    campaign.value!,
                                    interpellation,
                                    selectInterpellationRecipients(
                                        interpellation
                                    )
                                )
                            return object
                        },
                        {}
                    )
                }
                return {}
            })

            const availableInterpellationAndModes = computed(() => {
                const result: Record<string, Array<InterpellationMode>> = {}
                for (const key in mappingInterpellationsAndModes.value) {
                    if (sentInterpellationsAndModes.value.hasOwnProperty(key)) {
                        result[key] = mappingInterpellationsAndModes.value[
                            key
                        ].filter(
                            (mode) =>
                                !sentInterpellationsAndModes.value[
                                    key
                                ].includes(mode)
                        )
                    } else {
                        result[key] = mappingInterpellationsAndModes.value[key]
                    }
                }
                return result
            })

            const customQuestions = computed((): Array<CustomQuestion> => {
                if (campaign.value) {
                    return campaign.value.custom_questions
                } else {
                    return []
                }
            })

            const recipients = computed<Array<RecipientPublic>>(() => {
                if (recipient.value !== null) {
                    return [recipient.value]
                }

                if (!interpellation.value || !interpellationMode.value) {
                    return []
                }
                return selectInterpellationModeRecipients(
                    selectInterpellationRecipients(interpellation.value),
                    interpellationMode.value!
                )
            })

            return {
                // STATE
                campaign,
                interpellationIndex,
                interpellationMode,
                streamStatus,
                sender,
                shouldCustomizeEmail,
                generatedContent,
                generatedContentId,
                customQuestionsAnswers,
                isSendDone,
                recipient,
                sentInterpellationsAndModes,
                errorHappened,
                lastInterpellationId,
                // ACTIONS
                setCampaign,
                setRecipient,
                setInterpellation,
                setInterpellationMode,
                setStreamStatus,
                pickNonCustomizedGeneratedContent,
                setSender,
                reformulateContentSample,
                $reset,
                setIsSendDone,
                markContentAsSent,
                destinatariesInCampaign,
                // GETTERS
                campaignGeoType,
                language,
                isStreamingIdle,
                interpellation,
                interpellationModesAvailable,
                interpellationModesAvailableForSelectedRecipients,
                customQuestions,
                recipients,
                mappingInterpellationsAndModes,
                availableInterpellationAndModes,
            }
        },
        {
            persist: {
                paths: ['sender'],
            },
        }
    )()
}
