<template>
    <div class="mb-6">
        <label
            class="block mb-2 text-sm font-normal text-gray-900"
            v-if="label"
            >{{ label }}</label
        >
        <div class="quill-editor">
            <div ref="quillEditor"></div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, watch } from 'vue'
import Quill from 'quill'
import 'quill/dist/quill.snow.css'

interface Props {
    modelValue: string | null
    placeholder?: string
    label?: string
}

const props = withDefaults(defineProps<Props>(), {
    modelValue: '',
    placeholder: 'Type here...',
})

const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void
}>()

const quillEditor = ref<HTMLElement | null>(null)
let quill: Quill | null = null

onMounted(() => {
    if (!quillEditor.value) return

    quill = new Quill(quillEditor.value, {
        theme: 'snow',
        modules: {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike', 'link'],
                ['clean'],
            ],
        },
        placeholder: props.placeholder,
    })

    quill.root.innerHTML = props.modelValue || ''

    quill.on('text-change', () => {
        emit('update:modelValue', quill!.root.innerHTML)
    })
})

watch(
    () => props.modelValue,
    (newValue) => {
        if (quill && newValue !== quill.root.innerHTML) {
            quill.root.innerHTML = newValue || ''
        }
    }
)

onBeforeUnmount(() => {
    if (quill) {
        quill.off('text-change')
    }
})
</script>

<style scoped></style>
