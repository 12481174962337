import { components } from '@/schema'
// TODO fix type match check :
// import { assertTypesMatch, TypeEqualityGuard } from '@/utils/types'
import * as Yup from 'yup'

type ContentSampleEmailBase =
    components['schemas']['ContentSampleContentSampleEmail']
type ContentSampleCommentBase =
    components['schemas']['ContentSampleContentSampleComment']
type ContentSampleTweetBase =
    components['schemas']['ContentSampleContentSampleTweet']

export interface ContentSampleEmail extends ContentSampleEmailBase {
    type: 'email'
}

export interface ContentSampleComment extends ContentSampleCommentBase {
    type: 'comment'
}

export interface ContentSampleTweet extends ContentSampleTweetBase {
    type: 'tweet'
}

export type ContentSample =
    | ContentSampleEmail
    | ContentSampleComment
    | ContentSampleTweet

// TODO fix type match check :
// assertTypesMatch<
//     TypeEqualityGuard<components['schemas']['ContentSample'], ContentSample>
// >()

const emailContentSampleValidator: Yup.ObjectSchema<ContentSampleEmail> =
    Yup.object().shape({
        type: Yup.string().oneOf(['email']).required(),
        subject: Yup.string().required(),
        body: Yup.string().required(),
    })

const commentContentSampleValidator: Yup.ObjectSchema<ContentSampleComment> =
    Yup.object().shape({
        type: Yup.string().oneOf(['comment']).required(),
        text: Yup.string().required(),
    })

const tweetContentSampleValidator: Yup.ObjectSchema<ContentSampleTweet> =
    Yup.object().shape({
        type: Yup.string().oneOf(['tweet']).required(),
        text: Yup.string().required().max(280),
    })

export const contentSampleValidator = Yup.lazy((value: ContentSample) => {
    if (value.type === 'email') {
        return emailContentSampleValidator
    } else if (value.type === 'comment') {
        return commentContentSampleValidator
    } else if (value.type === 'tweet') {
        return tweetContentSampleValidator
    } else {
        throw new Error(`Invalid content sample type ${value['type']}`)
    }
})

export const defaultContentSampleEmail = (): ContentSampleEmail => ({
    type: 'email',
    subject: '',
    body: '',
})

export const defaultContentSampleComment = (): ContentSampleComment => ({
    type: 'comment',
    text: '',
})

export const defaultContentSampleTweet = (): ContentSampleTweet => ({
    type: 'tweet',
    text: '',
})

export const defaultContentSample = (
    contentSampleType: ContentSample['type']
): ContentSample => {
    switch (contentSampleType) {
        case 'email':
            return defaultContentSampleEmail()
        case 'comment':
            return defaultContentSampleComment()
        case 'tweet':
            return defaultContentSampleTweet()
        default:
            throw new Error(
                `Unexpected content sample type: ${contentSampleType}`
            )
    }
}
