<template>
    <StepContainer :campaign-id="props.campaignId">
        <StepTitle
            class="min-h-4"
            :titles="titles"
            :title-index="titlesIndex"
            :is-loading="!store.isStreamingIdle"
            :error-happened="errorHappened"
        />
        <div v-if="!errorHappened">
            <div
                v-if="store.streamStatus === StreamingStatus.ABORTED"
                class="w-full text-center text-warning mb-3"
            >
                <i class="fi fi-rs-triangle-warning mr-1"></i
                >{{ t('user.common.AIError') }}
            </div>
            <GenerateEmail
                v-if="store.interpellationMode === 'email'"
                @send-button-clicked="onSendButtonClicked"
                :campaign-id="props.campaignId"
            />
            <GenerateComment
                v-if="
                    ['facebook', 'instagram', 'form'].includes(
                        store.interpellationMode!
                    )
                "
                @send-button-clicked="onSendButtonClicked"
                :campaign-id="props.campaignId"
            />
            <GenerateTweet
                v-if="store.interpellationMode === 'twitter'"
                @send-button-clicked="onSendButtonClicked"
                :campaign-id="props.campaignId"
            />
        </div>
        <SendValidationModal
            v-if="isShowingSendValidationModal"
            @close-modal="isShowingSendValidationModal = false"
            :campaign-id="props.campaignId"
        />
    </StepContainer>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useSendCampaignStore } from '@/AppCampaigns/stores'
import GenerateEmail from './GenerateEmail/GenerateEmail.vue'
import GenerateComment from './GenerateCommentOrTweet/GenerateComment.vue'
import GenerateTweet from './GenerateCommentOrTweet/GenerateTweet.vue'
import SendValidationModal from './SendValidationModal.vue'
import StepTitle from '../StepTitle.vue'
import { onBeforeMount, computed, ref } from 'vue'
import StepContainer from '../StepContainer.vue'
import { StreamingStatus } from '../types'

interface Props {
    campaignId: string | null
}
const props = defineProps<Props>()

const { t } = useI18n()
const store = useSendCampaignStore(props.campaignId)
const isShowingSendValidationModal = ref<boolean>(false)
const errorHappened = computed(
    () => store.streamStatus === StreamingStatus.ERROR || store.errorHappened
)

const errorTitle = {
    title: t('user.common.errorTitle'),
    subtitle: t('user.common.errorSubtitle'),
}

const titles = computed<Array<{ title: string; subtitle?: string }>>(() => {
    return switchTitles.value.concat(errorTitle)
})

const switchTitles = computed<Array<{ title: string; subtitle?: string }>>(
    () => {
        switch (store.interpellationMode!) {
            case 'facebook':
                return [
                    {
                        title: t('user.email.getting_message_ready'),
                    },
                    {
                        title: t('user.campaign.comment_ready', {
                            interpellationName: store.interpellation!.name,
                        }),
                    },
                ]
            case 'instagram':
                return [
                    {
                        title: t('user.email.getting_message_ready'),
                    },
                    {
                        title: t('user.campaign.comment_ready', {
                            interpellationName: store.interpellation!.name,
                        }),
                    },
                ]
            case 'email':
                return [
                    {
                        title: t('user.email.getting_email_ready'),
                    },
                    {
                        title: t('user.email.email_ready'),
                        subtitle: t('user.email.email_ready_subtitle'),
                    },
                ]
            case 'twitter':
                return [
                    {
                        title: t('user.email.getting_message_ready'),
                    },
                    {
                        title: t('user.campaign.tweet_ready', {
                            interpellationName: store.interpellation!.name,
                        }),
                    },
                ]
            case 'form':
                return [
                    {
                        title: t('user.email.getting_message_ready'),
                    },
                    {
                        title: t('user.campaign.form_ready'),
                    },
                ]
            default:
                throw new Error(
                    'Interpellation mode not supported ' +
                        store.interpellationMode
                )
        }
    }
)

const titlesIndex = computed(() => {
    if (errorHappened.value) {
        return titles.value.length - 1
    }
    if (store.isStreamingIdle) {
        return 1
    }
    return 0
})

const onSendButtonClicked = () => {
    isShowingSendValidationModal.value = true
}

onBeforeMount(async () => {
    if (!store.interpellation) {
        throw new Error('Store should have an interpellation set')
    }
    if (!store.interpellationMode) {
        throw new Error('Store should have an interpellation mode set')
    }
    if (!store.recipients) {
        throw new Error('Store should have recipients set')
    }
    if (store.shouldCustomizeEmail) {
        await store.reformulateContentSample()
    } else {
        store.pickNonCustomizedGeneratedContent()
    }
})
</script>
