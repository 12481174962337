<template>
    <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-white">
            <tr class="rounded-t-xl">
                <ColumnHeader
                    v-for="{ name, tKey, icon } in props.columns"
                    :key="name"
                    :column="name"
                >
                    <template #label>
                        <span v-if="icon">
                            <i :class="`fi ${icon}`"></i>
                        </span>
                        <span v-else-if="tKey">
                            {{ t(tKey) }}
                        </span>
                        <span v-else>
                            {{ name }}
                        </span>
                    </template>
                </ColumnHeader>
            </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
            <tr
                v-for="(processedRow, index) in processedCells"
                :key="index"
                :class="`h-16 ${rows[index].rowClass} ${rows[index].route || rows[index].expandable ? 'cursor-pointer hover:bg-gray-100' : ''}`"
                @click="onRowClicked(props.rows[index], index)"
            >
                <td
                    v-for="(processedCell, idxCol) in processedRow"
                    :key="idxCol"
                    class="px-4 py-2"
                    :class="{
                        'text-ellipsis overflow-hidden whitespace-nowrap max-w-[20em]':
                            typeof processedCell.valueForDisplay === 'string',
                    }"
                >
                    <RouterLink
                        v-if="processedCell.route"
                        :to="processedCell.route!"
                    >
                        <span v-if="processedCell.icon">
                            <i :class="`fi ${processedCell.icon}`"></i>
                        </span>
                        <span v-else>
                            {{ processedCell.valueForDisplay }}
                        </span>
                    </RouterLink>
                    <span v-else>
                        <span v-if="processedCell.icon">
                            <i :class="`fi ${processedCell.icon}`"></i>
                        </span>
                        <span v-else>
                            {{ processedCell.valueForDisplay }}
                        </span>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script setup lang="ts" generic="T extends object">
import { RouterLink } from 'vue-router'
import ColumnHeader from './ColumnHeader.vue'
import { useI18n } from 'vue-i18n'
import { ColumnDefinition, RouteDefinition, RowDefinition } from './types'
import { useRouter } from 'vue-router'
import { computed } from 'vue'
import { serializeQueryParams } from '@/utils/requests'

interface Props {
    columns: Array<ColumnDefinition<T>>
    rows: Array<RowDefinition<T>>
}

const props = defineProps<Props>()

interface Emits {
    (event: 'row-clicked', index: number): void
}
const emit = defineEmits<Emits>()

const router = useRouter()
const { t } = useI18n()

const onRowClicked = (row: RowDefinition<T>, index: number) => {
    if (row.expandable) {
        emit('row-clicked', index)
    }
    if (row.route) {
        router.push(_serializeRoute(row.route))
    }
}

const processedCells = computed(() => {
    const columnNames = props.columns.map((column) => column.name)
    return props.rows.map((row) =>
        columnNames.map((columnName) => {
            const cell = row.cells[columnName]
            return {
                value: cell.value,
                valueForDisplay: _formatValue(
                    cell.valueForDisplay || cell.value
                ),
                icon: cell.icon || null,
                route: cell.route ? _serializeRoute(cell.route) : null,
            }
        })
    )
})

const _serializeRoute = (route: RouteDefinition) => ({
    ...route,
    query: route.query ? serializeQueryParams(route.query) : undefined,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const _formatValue = (value: any): string => {
    if (value instanceof Date) {
        return value.toLocaleString()
    } else if (typeof value === 'string') {
        return value
    } else if (typeof value === 'number') {
        return value.toString()
    } else {
        throw new Error(`Unsupported value type ${value}`)
    }
}
</script>
